.add-advertising{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .btn{
        margin: 12px 0px;
    }
.checkbox{
    display: flex;
    align-items: center;
    p{
        margin-left: 5px;
    }
}
.select-category{
    border: 1px solid #b1b1b1;
    padding: 5px;
}
.date-and-checkbox{
    display: flex;
    margin: 10px 0px;
    input[type="date" i]{
        margin-left: 15px;
    }
}
}
.advertising{
    display: flex;
    border: 1px solid;
    width: 70%;
    align-items: center;
    margin: 5px;
    .ads-detail{
        display: flex;
        flex-direction: column;
        width: 40%;
        padding: 5px;
        .btn{
            width: 25%;
        }
    }
}