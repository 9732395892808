.img-list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .img-container {
        position: relative;
        margin: 5px;
        
        .img{
            border: 2px solid #d5d5d5;
            margin: 5px;
        }
        .img-active{
            border: 2px solid $c1;
            margin: 5px;
        }
        img{
            padding: 2px;
            height: 190px;
            width: 190px;
        }
        .delete {
            position: absolute;
            top: 5px;
            right: 5px;
            background-color: #fff;
            height: 25px;
            width: 25px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: transform 0.3s, background-color 0.3s;
            &:hover {
                background-color: #f2f2f2;
                transform: scale(1.5);
            }
        }
        .edit {
            cursor: pointer;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 5px;
            left:7px;
            border-color: gray;
            border-width: 1px;
            transition: transform 0.3s, background-color 0.3s;
            
            &:hover {
                transform: scale(1.5);
            }
         }
          
    }
            
}

.btn{
    background-color: #e3032e;
    padding: 10px 15px;
    border-radius: 10px;
    color: #fff;
    border: 1px solid #e3032e;
    margin: 12px 20px;

    &:hover {
        color: $c1;
        background-color: #fff;

    }
    &.send{
        margin: 0px 10px;
        padding: 5px 10px;

    }
}
.add-img{
    display: flex;
    padding: 10px;
    align-items: center;

    .send{
        display: flex;
    }
}

.img-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    text-align: center;
}

.image-details {
    margin-top: 6px; 
    font-size: 14px;
    color: #333;
}

.image-details label {
    display: block;  
}

