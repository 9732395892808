.swal2-container.swal2-center>.swal2-popup {
    background-image: url('https://wstest.hamazon.co.il/img/iconcss/AlertBg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: transparent;
    height: 440px;
    align-items: center;

    .swal2-icon {
        margin: 0px auto;
        background-color: #fff;
    }

    .swal2-title {
        top: -70px;
        font-family: $font-open-sans-hebrew;
    }
}