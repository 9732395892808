.header-wrapper {
    height: max-content;
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
    background: #fff;
    font-family: 'Open Sans Hebrew';
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.07);

    .header-connection {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            width: 30px;
            height: 30px;
        }

        .back-and-logo {
            display: flex;
            align-items: center;
            width: 15%;
            margin-right: 1%;
            
            .back-button {
                background: none;
                border: none;
                cursor: pointer;
                font-size: 30px;
                margin-right: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px;
                color: #1d1d1b;
                
                &:hover {
                    color: $c1;
                }
            }
            
            .site-icon {
                width: 85%;
                
                img {
                    width: 100%;
                }
            }
        
        }

        .header-data {
            display: flex;
            position: relative;

            .data {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 5px;
                cursor: pointer;

                &:hover {

                    a,
                    span {
                        color: $c1;
                    }
                }

                &.open-menu {
                    z-index: 2;

                    &:after,
                    &:before {
                        content: "";
                        width: 12px;
                        height: 120%;
                        position: absolute;
                        bottom: -18px;
                        border-top: 0;
                    }

                    &:after {
                        border-radius: 0 0 15px 0;
                        left: 40px;
                        border-left: 0;
                        margin-left: 1px;
                        box-shadow: inset -5px 0 6px -7px rgb(0 0 0 / 60%)
                    }

                    &:before {
                        border-right: 0;
                        margin-right: 1px;
                        border-radius: 0 0 0 15px;
                        right: 30px;
                        box-shadow: inset 5px 0 6px -7px rgba(0, 0, 0, 0.7);
                    }
                }

                a {
                    text-decoration: none;
                    color: black;
                }

                .customer-service {
                    height: 22px;
                    border: none;
                    background-color: transparent;
                    text-decoration: none;
                    color: black;
                }

            }
        }

        .login-or-sign-in {
            margin-left: 15px;

            div {
                display: flex;
            }

            button {
                width: max-content;
                margin: 0px 5px;
                padding: 10px 15px;
                border-radius: 33px;
                border: solid 2px #1d1d1b;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                z-index: 2;
                cursor: pointer;

                &:focus {
                    color: #fff;
                    background-color: $c1;
                    border: $c1;
                }
            }

            .private-area-list-menu {
                position: absolute;
                background-color: #fff;
                z-index: 1;
                height: 450px;
                top: 0;
                border-radius: 0% 0% 30% 30%;
            }

            .close-menu {
                position: absolute;
                bottom: -15px;
                width: 100%;
                height: 135px;
                border-radius: 0% 0% 60% 60%;
                cursor: pointer;
            }
        }
    }

    .head-right-side {
        .menu {
            display: none;
        }

        .navigate {
            display: block;
        }

    }

}

.react-horizontal-scrolling-menu--scroll-container {
    display: flex;
    padding: 0px;
    align-items: flex-end;
    margin: 0px;
    flex-direction: row;
    height: 82px;

    .item-sale {
        position: relative;
        display: flex;
        border-left: 1px solid #fff;

        a {
            height: 75%;
            width: 100%;
            align-items: center;
            display: flex;
            justify-content: center;
            position: absolute;
            top: 65%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.3px;
            text-align: center;
            text-decoration: auto;
            color: #fff;



        }
    }

    .item {
        border-top: 1px solid #e7e7e7;
        padding: 5px;
        background-color: white;
        height: 70px;
        text-align: center;
        max-width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            height: 100%;
            width: 100%;
            align-items: center;
            display: flex;
            justify-content: center;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.1px;
            text-align: center;
            text-decoration: auto;
            color: black;

           
        }
    }
    
    .item-active{
        border-top: 1px solid #e7e7e7;
        padding: 4px;
        border-bottom: 3px solid black;
        height: 70px;
        text-align: center;
        max-width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            height: 100%;
            width: 100%;
            align-items: center;
            display: flex;
            justify-content: center;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.1px;
            text-align: center;
            text-decoration: auto;
            color: $c1;
            
        }
    }

}

.react-horizontal-scrolling-menu--inner-wrapper {
    align-items: flex-end;
}

.react-horizontal-scrolling-menu--wrapper {

    button {
        background-color: #f0f0f0;
        border: none;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        height: 80px;
        text-align: center;
        max-width: 130px;
    }
}

.left-btn {
    transform: rotate(90deg);
}

.right-btn {
    transform: rotate(-90deg);
}

.header-end {
    background-color: #f2f2f2;
    display: flex;
    height: 45px;

    img {
        width: 30px;
        height: 30px;
    }

    .filter-and-sort {
        display: flex;
        width: 10%;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
    }

    .cross-line {
        font-size: 30px;
    }

    .cart-in-header {
        border-top: 1px solid #f2f2f2;
        width: 30.5%;
        display: flex;

        .open-cart-btn {
            border: unset;
            padding: 0px 10px;
            background-color: $c2;
            border-radius: 0px 0px 10px 0px;
            width: 50px;

            .close-cart {
                transform: rotate(3.142rad);
            }
        }

        .sum-in-cart {
            display: flex;
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            background-color: $c1;
            width: -webkit-fill-available;
            justify-content: space-between;
            border-radius: 0px 0px 0px 10px;

            .sum-and-num-details {
                display: flex;
                align-items: center;
                padding: 0px 18px;
                width: 75%;
                justify-content: space-around;

                .num-product {
                    position: relative;
                    font-size: 9px;
                    font-weight: 400;
                    margin: 0px;
                    padding: 0px;
                    display: flex;

                    .num-product-text {
                        position: absolute;
                        width: 20px;
                        color: #e3032e;
                        right: 0px;
                        top: 5px;
                        text-align: center;
                    }

                    img {
                        width: 40px;
                        height: 40px;
                    }

                }

                .btn-price {
                    padding: 5px 16px;
                    font-size: 22px;

                    span {
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
        }

        .to-pay-btn {
            border-radius: 0px 0px 10px 10px;
            padding: 6px 15px;
            border: solid 2px $c1;
            background-color: #fff;
            font-size: 18px;
            font-weight: bold;
            color: $c1;
            text-decoration: none;
        }

    }
}

.desk-search {
    width: 60%;
    display: block;
}

.mobile-search {
    display: none;
}

.search-cont {
    height: 100%;
    display: flex;
    position: relative;
    margin-left: 10px;
    justify-content: space-evenly;
    align-items: center;

    input {
        background-color: #f2f2f2;
        border: unset;
        font-size: 18px;
        padding: 5px 20px 5px;
        width: 85%;
    }

    img {
        width: 30px;
        height: 30px;
        margin: 0px 5px;
        cursor: pointer;

    }
}

.toggle-nav {
    display: flex;

    img {
        display: inline-block;
        width: 30px;
        cursor: pointer;
        padding-right: 5px;
        padding-top: 10px;
    }
}

.missing-items-alert {
    font-size: 24px;
    text-align: center;
    line-height: 1.5;
    color: #333; 
}

.missing-items-container {
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
    text-align: center;
}

.missing-items-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.missing-items-container::-webkit-scrollbar-thumb {
    background-color: #d9534f;
    border-radius: 10px;
}

.missing-items-container::-webkit-scrollbar-thumb:hover {
    background-color: #c9302c;
}

.missing-items-container::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 10px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.missing-items-container {
    direction: ltr;
}

.station-user{
    font-size: 18px;
    font-weight: bold;
    display: flex;                  
    justify-content: center;
    align-items: center;    
    text-align: center; 
}

.custom-select {
    border: none; 
    background: none; 
    font-size: 20px;
    font-weight: bold;  
    color: inherit;
    outline: none;
    text-align: right;
    padding-right: 6px;
    cursor: pointer;
}

.custom-select option {
    color: black;
}

.arrow {
    font-size: 16px;
}

.custom-customer {
    padding-right: 6px;
}
  