.about-page {
    margin-top: -150px;
    width: 100vw;

    .all-text {
        width: 45%;
    }

    .about-header {
        font-size: 60px;
        line-height: 60px;
        font-weight: bold;
        color: $c1;
        margin: 10px 0px;
        padding: 0;
    }

    img {
        width: 45%;
    }

    .arrow-down {
        text-decoration: none;
        color: $c1;
        display: flex;
        margin-top: 15px;

        img {
            width: 25px;
            margin-left: 5px;
        }
    }

    .about-part {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 5% 25px;
        margin-bottom: 5%;

        &.aligned {
            &.price {
                background-color: #f2f2f2;
            }

            .all-text {
                width: 35%;
            }
        }


        .about-text {
            font-size: 16px;
            margin: 0;
            width: 60%;

            &.bold {
                font-weight: bold;
            }

            &.color {
                color: $c1;
            }
        }
    }

    .about-part-tow {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 5% 25px 10% 25px;
        background-image: linear-gradient(#1d3e4a, #091114);
        position: relative;
        justify-content: flex-end;

        .img-about {
            position: absolute;
            right: 0;
        }

        .about-text {
            color: #fff;
            margin: 0;
            width: 60%;

            &.bold {
                font-weight: bold;
            }
        }
    }

}