.product-pop-up {
    display: flex;
    justify-content: space-around;

    .product-img {
        width: 20%;

        img {
            max-width: 250px;
            max-height: 350px;            
        }

        .price-btn {
            display: flex;
            margin-right: 50px;
    
            .add-to-cart.not-in-cart {
                display: flex;
                opacity: 1;
            }
    
            .add-to-cart .wrap {
                width: 100px;
                height: 50px;
            }
        }
    }

    .product-details {
        width: 65%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .prod-name {
            font-size: 18px;
        }
        .prod-sale{
            color: $c1;
            font-weight: 500;
            margin: 0;
            font-size: 18px;
        }

        .prod-info-quantity {
            font-size: 13px;

            .weight-quantity {
                margin-left: 3px;
            }
        }

        .extra-info {
            display: flex;

            p {
                font-size: 14px;
            }
        }
    }

    .kashrut {
        width: 10%;
        padding-left: 10px;
        padding-top: 10px;

        img {
            width: 100%;
        }
    }
}

.img-popup {
    position: fixed;
    z-index: 10000;
    background-color: white;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;

    .close {
        cursor: pointer;

        img {
            margin: 15px 25px 5px 0px;
            width: 40px;

        }
    }

    .product-img {
        max-width: 90vw;
        height: 90vh;
    }
}

.product-details {
    .name {
        h2 {
            margin: 0;
        }
    }

    .details {
        p {
            margin: 0;
        }
    }
}

.product-add-to-shopping-list-pop-up {
    display: flex;
    margin: 15px;
    flex-direction: column;

    .select-list {
        display: flex;

        p {
            margin: 0%;
        }

        select {
            background-color: #fff;
            color: #000;
            border: 2px solid #000;
            border-radius: 5px;
            font-size: 18px;
            height: 25px;
            text-align: center;
            margin: 0px 5px;

            option {
                background-color: #fff;
                color: $c1;
            }
        }

        option:checked {
            background-color: $c1;
            color: #fff;
        }
    }

    .quantity-to-add {
        display: flex;
        margin: 15px 0px;

        p {
            margin: 0px;
        }

        input {
            width: 50px;
            margin: 0px 5px;
            font-size: 18px;
        }
    }

    .btn {
        font-size: 18px;
        padding: 5px 15px;
        margin: 0px;
    }

    .open-list-page {
        margin-top: 10px;
        text-decoration: none;
        color: #000;
        cursor: pointer;
        text-align: end;
    }
}

.outOfStock{
    color:#e3032e;
    text-align: center;
  }
  .price-compare-table {
    max-width: 1200px;
    align-items: center;
    width: 96%;
    margin: 10px auto;
    padding: 20px 8px;
    border: 1px solid black;
    text-align: center;

    .table-container {
        max-height: 150px;  // הגבלת גובה הטבלה
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 5px;  // הקטנת הרווח הפנימי
        text-align: start;
        line-height: 0.5; // גובה שורה נמוך יותר
    }

    tr {
        height: 15px;  // קביעת גובה מקסימלי לשורה
    }

    tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    th {
        background-color: $c1;
    }

    .sale-row td {
        background-color: inherit;
        color: #721c24;
        font-weight: bold;
        
    }

    .table-title {
        margin-top: 0px;
        text-align: center;
        font-size: 16px;  // הפחתת גודל הגופן
        margin-bottom: 5px;  // הקטנת הרווח התחתון
        background-color: $c1;
        padding: 5px 10px;  // הפחתת הרווח הפנימי
    }
}

/* Main Container Styles */
.product-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    direction: rtl;
  }
  
  .product-wrapper-compare {
    margin-bottom: 30px;
  }
  
  /* Typography */
  .product-title {
    text-align: center;
    margin: 0 0 20px 0;
    font-weight: bold;
  }
  
  .product-description {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Row Styles */
  .info-row {
    margin-bottom: 20px;
  }
  
  .last-row {
    margin-bottom: 40px;
  }
  
  .info-col {
    text-align: center;
  }
  
  /* Form Elements */
  .field-group {
    margin-bottom: 15px;
  }
  
  .field-label {
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
  }
  
  .input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .number-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
  }
  
  .textarea-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    max-height: 35px;
    resize: vertical;
  }
  
  .margin-checkbox {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .checkbox-input {
    margin-left: 5px;
  }
  
  /* Date Picker Styles */
  .date-container {
    display: flex;
    justify-content: center;
    gap: 30px;
  }
  
  .date-picker-wrapper {
    width: 30%;
  }
  
  .date-label {
    display: block;
    margin-bottom: 5px;
  }
  
  .date-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  /* X/Y Input Section */
  .xy-row {
    margin-bottom: 20px;
    margin-right: 30%;
  }
  
  .xy-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
  }
  
  .xy-input {
    width: 30%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .date-container {
      flex-direction: column;
      gap: 15px;
    }
    
    .date-picker-wrapper {
      width: 100%;
    }
    
    .xy-row {
      margin-right: 0;
    }
    
    .xy-container {
      flex-wrap: wrap;
    }
    
    .xy-input {
      width: 40%;
    }
  }