.user-entry {
	font-family: $font-open-sans-hebrew;

	.user-entry-header {
		background-color: $c1;
		width: 95.7%;
		position: absolute;
		border-top-right-radius: 30px;
		border-top-left-radius: 30px;
		top: 15px;
		padding: 0px;
		margin: 0px;
		height: 60px;

		h3 {
			font-size: 26px;
			text-align: center;
			color: #fff;
			margin: 0px;
			padding: 10px 0px;
		}

		&.reset {
			width: 94%;
		}
	}



	.login-form {
		margin: auto;
		margin-top: 135px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 40%;

		.p-title {
			font-size: 16px;
		}

		input {
			height: 30px;
			width: 250px;
			border-radius: 4.7px;
			border: solid 2px #e6e6e6;
		}

		.login-btn {
			cursor: pointer;
			width: 150px;
			height: 40px;
			margin: 10px auto;
			border: none;
			border-radius: 25px;
			color: #fff;
			background-color: $c1;
			font-size: 18px;
		}

		.forgot-pass {
			cursor: pointer;
			margin: 0px auto 25px;
			font-size: 14px;
		}

		&.reset {
			display: flex;
			margin-top: 100px;
			align-items: center;
		}

	}

	.cancel {
		cursor: pointer;
		border: none;
		background: no-repeat;
		position: absolute;
		bottom: 30px;
		left: 45px;
		font-size: 14px;
		font-weight: bold;
		line-height: 1.2;
		color: #1d1d1b;
	}

	.forgot-pass-wrap {
		margin: 0px auto;
		margin-top: 135px;
		margin-bottom: 20px;
		display: flex;
		width: 60%;
		flex-direction: column;
		align-items: center;

		input {
			height: 30px;
			width: 250px;
			border-radius: 4.7px;
			border: solid 2px #e6e6e6;
		}

		.send-btn {
			cursor: pointer;
			width: 150px;
			height: 40px;
			margin: 10px auto;
			border: none;
			border-radius: 25px;
			color: #fff;
			background-color: $c1;
			font-size: 18px;
		}
	}
}

.register-form {
	margin: auto;
	margin-top: 130px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.note {
		font-size: 18px;
		margin-right: 15px;
		color: red;
	}

	.details-form {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;

		.row-form {
		    margin-right: 5%;
			gap: 50px;
			width: 42%;
			display: flex;
			flex-direction: row;

			.p-title {
				font-size: 16px;
				margin-bottom: 2px;
			}

			input {
				height: 25px;
				width: 250px;
				border-radius: 4.7px;
				border: solid 2px #e6e6e6;
			}
		
			.password-input {
				position: relative; 
				height: 25px;
				width: 212px;
				border-radius: 4.7px;
				border: solid 2px #e6e6e6;
				padding-right: 40px; 
			}
			
			.input-wrapper {
				position: relative;
				}
				
				.password-icon {
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
				cursor: pointer;
				color:black;
				}
				
			
			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			input[type=number] {
				-moz-appearance: textfield;
			}

			.checkbox-style {

				span {
					margin-top: 25px;
					margin-left: 30px;
					display: flex;
					flex-direction: row-reverse;

					.verify {
						height: 50px;
						width: 200px;
						font-size: 12px;
						display: block;
						margin: 0;
					}
				}
			}

			input[type=checkbox] {
				width: auto;
			}


			.register-btn {
				cursor: pointer;
				width: 200px;
				height: 30px;
				margin: 15px auto;
				margin-right: 20px;
				border: none;
				border-radius: 25px;
				color: #fff;
				background-color: $c1;
				font-size: 18px;
			}

			.invoicing-name {
				.invoicing-radio-btn {
					display: flex;
					align-items: center;
					margin-bottom: 10px;

					p {
						margin: 0px;
						margin-top: 10px;
					}

					#MyName,
					#OtherName {
						accent-color: $c1;
						height: 20px;
						width: 25px;
					}
				}
			}
		}
	}
}

.register-mail {

	.register-head {
		background-color: $c1;
		color: #fff;
		width: 95.7%;
		position: absolute;
		border-top-right-radius: 30px;
		border-top-left-radius: 30px;
		top: 15px;
		padding: 0px;
		margin: 0px;
		height: 60px;
		text-align: center;

	}

	.register-body {
		margin: auto;
		margin-top: 60px;
		display: flex;
		width: 50%;
		text-align: center;
		flex-direction: column;
		align-items: center;

		input {
			font-size: 18px;
			width: 150px;
		}
	}
}


.tab-header {
	height: 45px;
	background-color: $c1;

	.tab {
		cursor: pointer;
		width: 50%;
		background-color: $c1;
		border: none;
		font-size: 20px;
		color: #fff;
		height: 100%;

		&.login {
			cursor: pointer;
			background-color: #fff;
			color: $c1;
			border-bottom: 0;
			width: 49%;
			margin-right: 1%;
			position: relative;
			border-radius: 15px 15px 0 0;

			&:after,
			&:before {
				content: '';
				width: 7px;
				height: 10px;
				background-color: $c1;
				position: absolute;
				bottom: -5px;
				border-top: 0;
			}

			&:after {
				width: 102%;
				right: 100%;
				border-radius: 0 0 15px 0;
				left: -10px;
				border-left: 0;
				margin-left: 1px;
			}

			&:before {
				border-right: 0;
				margin-right: 1px;
				border-radius: 0 0 0 15px;
				right: -8px;
			}
		}

		&.signIn {
			cursor: pointer;
			background-color: #fff;
			color: $c1;
			border-bottom: 0;
			width: 49%;
			margin-left: 1%;
			position: relative;
			border-radius: 15px 15px 0 0;

			&:after,
			&:before {
				content: '';
				width: 7px;
				height: 10px;
				background-color: $c1;
				position: absolute;
				bottom: -5px;
				border-top: 0;
			}

			&:after {
				border-radius: 0 0 15px 0;
				left: -8px;
				border-left: 0;
				margin-left: 1px;
			}

			&:before {
				border-radius: 0 0 0 15px;
				width: 102%;
				left: 99.8%;
				border-left: 0;
				margin-left: 1px;
			}
		}
	}
}
  
.password-toggle {
	position: absolute;
	top: 63%;
	right: 440px;
	transform: translateY(-50%); 
	cursor: pointer;
}

.new-signup-popup{
	align-items: center;
	padding-right: 10px;
}

.site-icon-address{
	width: 100%;
	border: #c6c619;
	img {
		width: 30%;
		margin-right: 33%;
	}
}