.users {
    .users-header {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: row;

        .arrow-side {
            display: flex;
            width: 100%;
            justify-content: flex-start;

            img {
                cursor: pointer;
                transform: rotate(180deg);
                margin: 10px;
            }

        }

    }

    .search {
        display: flex;
        position: relative;

        input {
            height: 30px;
        }

        img {
            position: absolute;
            width: 25px;
            left: 5px;
            top: 8px;
        }
    }

    .users-table {
        width: 95%;

        td {
            text-align: center;

            img {
                width: 35px;
            }

            .user-info {
                margin: 0px 15px;
                padding: 10px;
                text-align: right;
            }
        }
    }
}
    .pagination {
        display: flex;
        justify-content: space-between;
        padding: 24px 0;
        border-top: 1px solid #ddd;

        ul {
            li {
                display: inline-block;
                width: 48px;
                height: 48px;
                border: 1px solid #ddd;
                color: $text;
                font-size: 16px;
                border-radius: 4px;
                cursor: pointer;
                text-align: center;
                margin: 0 8px;
                vertical-align: middle;

                &:hover {
                    background: #f5f5f5;
                }

                &.active {
                    background: $c1;
                    color: #fff;
                    border: 1px solid $c1;
                }

                &.disable {
                    opacity: 0.5;
                }

                &[data-active="active"] {
                    margin-right: 73px;
                    position: relative;

                    &:before {
                        content: '...';
                        position: absolute;
                        width: 48px;
                        height: 48px;
                        line-height: 35px;
                        font-size: 24px;
                        border: 1px solid #ddd;
                        border-radius: 4px;
                        top: -1px;
                        right: -67px;
                    }
                }

                span {
                    display: block;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                }

                img {
                    display: block;
                    width: 20px;
                    margin: 0 auto;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }


.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: calc(100% - 42px);
    height: 30px;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input:focus {
    outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: 50px;
    width: calc(100% - 2px);
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 400;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    max-height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border: 2px solid #ddd;
    }

    &::-webkit-scrollbar {
        width: 10px;
        background-color: #fff;
    }
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion:not(:first-child) {
    border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--focused {
    background-color: #0C7EAF;
    color: #fff;
}

.suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
}

.highlight {
    color: #ee0000;
    font-weight: bold;
}

.react-autosuggest__suggestion--focused .highlight {
    color: #120000;
}

.react-autosuggest__suggestion--highlighted {
    background: linear-gradient(180deg, #139cd1, #168bb8) !important;
    color: #fff;
}
