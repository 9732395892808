.add-station {
    .row {
        display: flex;
        width: 100%;
    }

    .p-title {
        display: flex;
        align-items: center;
        margin: 5px 10px;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

    }

    input {
        margin: 5px;
        width: 175px;
        font-size: 18px;
    }

    .btn {
        color: #fff;
        padding: 5px 25px;
        height: 45px;
        border-radius: 5px;
        margin: 5px;
        background-color: $c1;
        font-size: 20px;
        cursor: pointer;

        &:hover {
            border: 1px solid $c1;
            background-color: #fff;
            color: $c1 !important;
        }
    }

}