.address-class {
    background-color: #fff;
    margin: 0;
    padding: 25px 15px 10px 15px;
    margin-bottom: 25px;
    border-radius: 0px 0px 30px 30px;

    .address-data {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        height: 100%;

        .address-data-list {
            border: none;
            margin: 10px;
            height: 120px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            .location-icon {
                height: 30px;
                margin-top: 7px;
            }

            .location-name {
                font-size: 20px;
            }

            .location-address-name {
                font-size: 14px;
                margin: 2px 7px;
                width: 100px;
            }

            .delete {
                position: absolute;
                right: 0px;
                top: -3px;
                background-color: #fff;
                height: 25px;
                border-radius: 50%;
                cursor: pointer;

                &:hover {
                    background-color: #f2f2f2;
                    transform: scale(1.5);
                }
            }


            &.active {
                color: #fff;
            }
        }

        .add-new-address {
            border: none;
            margin: 5px 3px;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            height: 120px;

            .location-add-icon {
                height: 30px;
                margin-top: 10px;
                filter: invert(1);
                margin-bottom: 5px;
            }

            .location-add-name {
                font-size: 21px;
                width: 50%;
            }

        }
    }
}

.add-address {
    background-color: #fff;
    margin: 0;
    padding: 25px 15px 10px 15px;
    margin-bottom: 25px;
    border-radius: 0px 0px 30px 30px;

    .note {
        font-size: 18px;
        margin-right: 15px;
        color:red;
    }

    .autocomplete-address {
        display: flex;
        margin: 0px 15px;
        flex-direction: column;

        p {
            margin: 2px 0px;
            font-size: 16px;
        }

        .autocomplete-address-input {
            height: 25px;
            border-radius: 4.7px;
            border: solid 2px #e6e6e6;
        }
    }

    .details-address {
        display: flex;
        width: 100%;
        margin: 10px 15px;

        .name {
            margin-left: 5%;
            width: 30%;

            span {
                display: flex;
                flex-direction: column;

                input {
                    height: 25px;
                    border-radius: 4.7px;
                    border: solid 2px #e6e6e6;
                }
            }
        }

        .sub-details {
            width: 65%;
            display: flex;

            span {
                display: flex;
                flex-direction: column;

                input {
                    height: 25px;
                    border-radius: 4.7px;
                    border: solid 2px #e6e6e6;
                    width: 50%;
                }
            }
        }
    }

    .note-shipping {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 15px;

        span {
            display: flex;
            flex-direction: column;
            width: 70%;

            .p-title {
                font-size: 16px;
            }

            input {
                height: 25px;
                border-radius: 4.7px;
                border: solid 2px #e6e6e6;
            }
        }

        .elevators-radio-btn {
            width: 22%;
            display: flex;
            flex-direction: column;

            .elevators {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 14px;

                #yesElevators,
                #noElevators {
                    accent-color: $c1;
                    height: 20px;
                    width: 18px;
                }
            }
        }
    }

    .checkbox-and-btn {
        display: flex;
        align-items: center;
        margin: 10px 15px;
        width: 100%;

        .checkbox-style {
            flex-direction: row;
        }

        .btn {
            cursor: pointer;
            width: 150px;
            height: 40px;
            margin: 10px auto;
            border: none;
            border-radius: 25px;
            color: #fff;
            background-color: $c1;
            font-size: 18px;
        }
    }
}