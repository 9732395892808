.filters {
    min-height: 100vh;
    padding-bottom: 40px;

    .filter-container {
        background-color: #fff;
        border: 5px solid #f6f7fb;
        padding: 10px;
        margin: 5px;
        width: 95%;
        overflow: hidden;
    }

    .editor-for-title {
        text-align: center;

        input {
            font-size: 18px;
            padding: 5px;
            border: none;
        }
    }

    ul {
        list-style-type: none;

        .sub-title {
            display: flex;
            justify-content: space-around;
            align-items: center;

            padding: 5px 0px;
            margin: 5px 0px;

            &:hover {
                background: #f6f6f6;
            }

            img {
                width: 20px;
            }

            input {
                font-size: 16px;
                &:active,
                &:focus {
                    outline: none;
                }
            }
        }

    }


}

.add-item {
    .btn {
        background-color: $c1;
        padding: 5px 10px;
        border-radius: 10px;
        color: #fff;
        display: flex;
        align-items: center;

        span {
            margin-right: 10px;
        }

        img {
            width: 25px;
        }
    }
}