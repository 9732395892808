@media all and (max-width: 1000px) {
.menu {

    display: flex;
    img {
        display: block;
        width: 32px;
        position: relative;
        top: 11px;
    }
}
.desk-search {
    display: none;
}

.mobile-search {
    display: block;
}

.search {
    top: 0px;
    left: 0px;
    margin-top: -15%;
    position: absolute;
}
}