.categories-container {
  padding: 16px 0;
}

.nested-categories {
  margin-top: 16px;
  margin-left: 20px;
}

.category-item {
  margin: 10px 100px;
  transition: transform 0.2s ease;
}

.category-content {
  padding: 16px;
  margin: 8px 20px;
  border-radius: 8px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.level-1 {
  background-color: #f0f9ff;
}

.level-2 {
  background-color: #e0f7fa;
}

.rename-input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  flex: 1;
  margin-right: 8px;
  font-size: 1.2em;
  font-weight: bold;
}

.rename-button,
.toggle-active-button {
  background: none;
  border: none;
  cursor: pointer;
}

.rename-form {
  display: flex;
  align-items: center;
}

.category-name {
  font-weight: bold; 
}

.add-category-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: red;
}

.add-category-item {
  margin: 10px 100px; 
  transition: transform 0.2s ease;
  cursor: pointer;
}

.add-category-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin: 8px 20px;
  border-radius: 8px;
  border: 2px dashed #ff5b5b;
  background-color: #ffecec;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: #ffdada;
  }
  
  .plus-icon {
    color: #ff5b5b;
    font-size: 25px;
    font-weight: bold;
  }

  p {
    margin: 0;
    font-size: 18px;
    color: #ff5b5b;
    font-weight: bold;
    text-align: center;
  }
}
