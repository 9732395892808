@import 'scss/Variables.scss';
@import '../js/components/header/Header.scss';
@import '../js/components/footer/Footer.scss';
@import '../js/components/header/UserEntry.scss';
@import 'scss/Mobile.scss';
@import 'scss/ShowAlert.scss';
@import 'scss/Accessibility.scss';
@import 'scss/CheckBox.scss';
@import '../js/components/routs/card/Card.scss';
@import '../js/components/routs/categoryPage/CategoryPage.scss';
@import '../js/components/routs/contacts/Contacts.scss';
@import '../js/components/nav-role/Nav.scss';
@import '../js/components/routs/home/Home.scss';
@import '../js/components/routs/categoryEdit/CategoryEdit.scss';
@import '../js/components/routs/productsEdit/ProductsEdit.scss';
@import '../js/components/routs/adminImg/AddImg.scss';
@import '../js/components/routs/filters/Filters.scss';
@import '../js/components/tools/MyCropper.scss';
@import '../js/components/routs/history/History.scss';
@import '../js/components/routs/inventoryUpdate/InventoryUpdate.scss';
@import '../js/components/routs/DeliveryTimeEdit/DeliveryTime.scss';
@import '../js/components/routs/listProductNewImg/ListProductNewImage.scss';
@import '../js/components/routs/address/StationPlace.scss';
@import '../js/components/routs/Station/AddStation.scss';
@import '../js/components/miniCart/MiniCart.scss';
@import '../js/components/routs/productDetailsEdit/productEditForm.scss';
@import '../js/components/routs/users/Users.scss';
@import '../js/components/routs/station/Location.scss';
@import '../js/components/routs/Advertising/Advertising.scss';
@import '../js/components/routs/sales/Sale.scss';
@import '../js/components/routs/productPage/ProductPopUp.scss';
@import '../js/components/productInfo/ProductInfo.scss';
@import '../js/components/routs/cart/Cart.scss';
@import '../js/components/privateArea/PrivateArea.scss';
@import '../js/components/routs/shoppingList/ShoppingList.scss';
@import '../js/components/addressShipping/Address.scss';
@import '../js/components/about/AboutHamazonSite.scss';
@import '../js/components/customerService/customerService.scss';
@import '../js/components/routs/shopCart/PayPopup.scss';
@import '../js/components/routs/userHistory/UserHistory.scss';
@import '../js/components/routs/shopCart/PayNedarim.scss';
@import '../js/components/routs/address/PopAddress.scss';

@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css);



body {
	margin: 0px;
	.pac-container{
		z-index: 10004;
	}
}

.flex-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.col-lg-9 {
		width: 75%;
	}

	.col-lg-8 {
		width: 80%;
	}

	.col-lg-6 {
		width: 40%;
	}

	.col-lg-7 {
		width: 50%;
	}

	.col-lg-4 {
		width: 33.33333333%;
	}

	.col-lg-3 {
		width: 25%;
	}

	.col-lg-2 {
		width: 16.66666667%;
	}

}

.popup {
	font-family: $font-open-sans-hebrew;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1001;
	background: rgba(67, 67, 67, 0.4);

	.popup-wrapper {
		overflow: hidden;
		position: relative;
		max-width: 1000px;
		width: calc(100% - 32px);
		border-radius: 4px;
		transform: translateY(120px);
		margin: 0 auto;
		overflow-y: auto;



		.close-popup {
			position: absolute;
			top: 0px;
			right: 0px;
			cursor: pointer;
			z-index: 999;

			img {
				display: block;
				width: 50px;
			}

			&.system {
				top: 10px;
				right: 10px;
			}
		}

		.children-popup {
			background-color: white;
			border-radius: 30px;
			box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
			margin: 15px;
			padding: 20px 0px;
		}

		.system-popup-child {

			.system-message {
				width: 85%;
				margin: auto;

				.header-system-text {
					display: block;
					padding: 25px 0px;
					font-size: 35px;
					margin: 0;
					font-weight: bold;
					text-align: center;
					color: #fff;
				}

				.system-body {
					position: relative;

					.system-img {
						width: 250px;
						height: 250px;
						object-fit: cover;
						object-position: 75% 50%;
						position: absolute;
						top: -85px;
						right: -65px;
					}

					.system-text {
						margin-top: 35px;
						text-align: center;

						h1,
						h2,
						strong {
							margin: 0;
							font-weight: 400;
							font-size: 16px;
						}
					}
				}
			}
		}

		&.animated {
			animation-duration: 1s;
			animation-fill-mode: both;
		}


	}
}

.header-system-text {
	display: none;
}

main {
	font-family: $font-open-sans-hebrew;
	overflow-y: auto;
	transform: none;
	transition: transform 0.7s ease 0s;
	margin-top: 15%;
	min-height: 100vh;

	&[data="active"] {
		transform: translateX(-300px);
	}

	&[data-cart="active"] {
		transition: width 0.5s ease 0s;
		margin-right: 10px;
	}

	&[data-notification="active"] {
		transition: transform 0.5s ease 0s;
	}
}

.title-role {
	background: #f6f7fb;
	padding: 15px;
	color: $c1;
}

//קשור לטסט של חץ במחלקותעיע
.react-horizontal-scrolling-menu--wrapper {
	direction: ltr;
}

.react-horizontal-scrolling-menu--scroll-container {
	overflow: hidden
}

;

.flex{
	margin: 10px;
	display: flex;

	.load-image-wrapper {
		position: inherit;
		margin-right: 15px;
	}
}
