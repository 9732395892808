.accessibilityAll {
    .copyright {
        position: relative;
        background: #1e1d24;
        padding: 16px 0;
        img {
            display: block;
            width: 150px;
            margin: 0 auto;
        }
        a.privacy {
            position: absolute;
            top: 20px;
            left: 5%;
            color: #fff;
        }
    }
    .actions {
        position: fixed;
        right: 0;
        left: 0;
        width: 100%;
        bottom: 0;
        z-index: 999;
        background: #898989;
        text-align: center;
        ul {
            display: flex;
            text-align: left;
            li {
                display: inline-block;
                vertical-align: middle;
                width: 100%;
                div {
                    position: relative;
                    height: 100%;
                }
                .icon.back {
                    border-right: none;
                    &:before {
                        display: none;
                    }
                }
                .icon {
                    position: relative;
                    cursor: pointer;
                    background: #898989;
                    width: 100%;
                    height: 100%;
                    padding: 14px;
                    border-style: solid;
                    border-left-width: 1px;
                    border-right-width: 1px;
                    border-left-color: rgba(255, 255, 255, 0.16);
                    border-right-color: #676767;
                    border-top: 0;
                    border-bottom: 0;
                    cursor: pointer;
                    transition: all 0.5s;
                    &:hover {
                        background: darken(#898989, 5%);
                    }
                    span {
                        position: absolute;
                        top: 8px;
                        right: 8px;
                        font-size: 10px;
                        color: #fff;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background: $c2;
                    }
                    img {
                        display: block;
                        width: 24px;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}
.sizeL {
	font-size: 125% !important;
}
.sizeXL {
	font-size: 150% !important;
}
.sizeXXL {
	font-size: 200% !important;
}
.sizeXXXL {
	font-size: 250% !important;
}
.zoomL {
	zoom: 1.15;
}
.zoomXL {
	zoom: 1.25;
}
.zoomXXL {
	zoom: 1.35;
}
.grayscale_body {
	-webkit-filter: grayscale(1);
	filter: grayscale(1);
}
.invert_body {
	-webkit-filter: invert(1);
	filter: invert(1);
}
.underline_links {
	text-decoration: underline !important;
}
.links_bolder {
	font-weight: 700 !important;
}
.accessibility-wrapper {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	background: #fff;
	z-index: 9998;
	box-shadow: 1px 1px 6px #000;
	.open-accessibility {
		position: absolute;
		right: -2px;
		top: 80vh;
		background: #122231;
		padding: 8px 10px;
		border-radius: 30px 0 0 30px;
		border: 2px solid #fff;
		cursor: pointer;
		img {
			display: inline-block;
			vertical-align: middle;
			width: 24px;
			&.close {
				width: 16px;
				padding-left: 8px;
				margin-left: 8px;
				border-left: 1px solid #fff;
			}
		}
	}
}
.accessibility {
	clear: both;
	max-width: 1200px;
	margin: 0 auto;
	padding-top: 20px;
	display: none;
}
.accessibility-contant{
    display: flex;
}
.accessibility-footer {
	clear: both;
	height: 10px;
}
.col-lg-3{
    width: 25%;
}
.accessibility .col-lg-4 {
	float: right;
	width: 25%;
}
.accessibility h3 {
    font-size: 30px !important;
    font-weight: 400;
    margin: 0;
}
.accessibility ul {
	position: relative;
	margin: 15px 0;
	padding: 0;
	list-style-type: none;
}
.accessibility ul:before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	right: 40px;
	height: 100%;
	width: 1px;
	background: #ddd;
	left: 40px !important;
	right: auto !important;
}
.accessibility .col-lg-3:last-child ul:before {
	display: none;
}
.accessibility ul li {
	font-size: 20px !important;
	cursor: pointer;
	height: 40px;
	display: block;
}
.accessibility ul li span {
	position: relative;
	display: inline-block;
	top: 5px;
	padding-right: 10px;
}
@media all and (max-width: 1250px) {
	.accessibility {
		width: 90%;
	}
	.accessibility ul:before {
		display: none;
	}
}
@media all and (max-width: 1025px) {
	.accessibility ul li {
		font-size: 18px !important;
	}
}
@media all and (max-width: 820px) {
	.accessibility .col-lg-4 {
		width: 50%;
	}
}
@media all and (max-width: 480px) {
	.accessibility .accessibility-contact .col-lg-3 {
		width: 100%;
		float: none;
		padding-bottom: 8px;
	}
	.accessibility ul {
		margin: 5px 0;
	}
	.accessibility ul li {
		height: 35px;
		width: auto !important;
		display: inline-block !important;
		padding-left: 15px;
		a {
			bottom: 2px !important;
			padding-right: 4px;
		}
	}
	.accessibility ul li span img {
		width: 24px;
	}
	.accessibility h3 {
		font-size: 24px !important;
	}
	.accessibility-contact .col-lg-4 {
		&:first-child {
			display: none;
		}
	}
}