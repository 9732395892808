.customer-service-menu {
    display: flex;
    position: absolute;
    width: 70%;
    right: 12%;
    height: 335px;
    bottom: -335px;
    flex-direction: column;
    z-index: 1;
    border-radius: 0% 0% 30% 30%;
    background-color: #fff;

    .header{
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin: 5px 0px;
        padding-top: 15px;
    }
    
    .option{
        cursor: pointer;
        border: none;
        background: none;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 15px;
        text-decoration: none;
        color: #000;
    }
    .tel-num{
        display: flex;
        align-items: center;
        flex-direction: column;
    }

.close-menu {
    position: absolute;
    bottom: -15px;
    width: 100%;
    height: 95px;
    border-radius: 0% 0% 60% 60%;
    cursor: pointer;
}
}