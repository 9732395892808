.products-edit {
    .add-product {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 0px 8px;
        background-color: $c1;
        border-radius: 10px;
        color: #fff;
        border: 1px solid $c1;

        &:hover {
            color: $c1;
            background-color: #fff;
        }

        img {
            width: 20px;
        }

        span {
            font-size: 16px;
            padding-right: 5px;
        }
    }

    .search {
        display: flex;
        align-items: center;

        input {
            padding: 5px;
            margin: 5px;
            font-size: 18px;
        }

        img {
            height: 30px;
        }
    }

    .product-edit-table {
        table {
            width: 96%;

            tr:nth-child(even) {
                background-color: #f9f9f9;
            }

            tr {

                &:hover {
                    background: #f1f1f1;
                }
            }
        }

        .enter-img {
            img {
                width: 30px;
            }

        }

        .sort-item {
            ul {
                list-style-type: none;
                padding: 0px;

                li {
                    background: transparent;
                    padding: 10px 0;
                    width: 40px;
                    cursor: pointer;
                    border-radius: 2px;
                    margin: 0 auto;

                    &:active {
                        transform: scale(0.9);
                    }

                    &:hover {
                        background: #909090;

                        img {
                            filter: invert(1) brightness(4);
                        }
                    }

                    img {
                        display: block;
                        width: 20px;
                        margin: 0 auto;
                    }
                }
            }
        }

        .category-img {
            position: relative;

            img {
                width: 50px;
            }

            .masc {
                position: absolute;
                top: -80px;
                right: 80px;
                width: 400px;
                z-index: 99;
            }

            img.main-img {
                border: none;
                object-fit: cover;
                height: 100%;
                display: block;
            }

            .img-load {
                width: 80px;
                height: 80px;
                margin: 0 auto;
            }

        }

        .title {
            text-align: center;

            .prod-det-div {
                display: flex;
                margin-top: 5px;

                span {
                    margin-left: 20px;
                    font-size: 14px;
                }
            }

        }

        .status {
            img {
                width: 30px;
            }

            .input {
                background: transparent;
                border: 1px solid #ccc;

                img {
                    opacity: 0;
                }

                &.active {
                    border: 1px solid transparent;

                    img {
                        opacity: 1;
                    }
                }
            }
        }
    }
}