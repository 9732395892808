.contacts-company {
    h3 {
        color: $text;
        margin: 2px 0px;
        text-align: center;
    }
    .info {
        margin-top: 60px;
        .flex-container {
            align-items: center;
        }
        .img {
            width: 60px;
            height: 60px;
            padding: 16px;
            border-radius: 50%;
            img {
                display: block;
                margin: 0 auto;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .text {
            margin-right: 16px;
            h3 {
                margin: 0;
                font-size: 32px;
                color: $c1;
                text-align: right;
            }
            p {
                margin-bottom: 0;
                color: $text;
            }
        }
    }
    .social {
      margin-top: 60px;
      text-align: center;
      ul {
        li {
          display: inline-block;
          vertical-align: middle;
          margin: 0 24px;
          img {
            display: block;
            height: 50px;
            transition: transform .2s;
            &:hover{
              transform: scale(1.2);
            }
          }
        }
      }
    }
    .form {
        margin-top: 80px;
        margin-bottom: 80px;
        .input-group {
            margin-bottom: 12px;
        }
        .flex-container {
            align-items: center;
        }
        .col-lg-6 {
            &:first-child {
                .wr {
                    padding-left: 8px;
                }
            }
            &:last-child {
                .wr {
                    padding-right: 8px;
                }
            }
        }
        .wr {
            input {
                box-sizing: border-box;
                width: 100%;
                padding-right: 16px;
                height: 51px;
                margin-bottom: 16px;
                border: 1px solid #ddd;
                font-size:16px;
            }
            textarea {
                box-sizing: border-box;
                width: 100%;
                padding-top: 16px;
                padding-right: 16px;
                height: 117px;
                margin-bottom: 8px;
                border: 1px solid #ddd;
                resize: none;
                font-size:16px;
            }
            .button-wrapper {
                text-align: center;
                button {
                    background: $c1;
                    color: #fff;
                    border: none;
                    cursor: pointer;
                    padding: 16px;
                    margin-top: 4px;
                    font-size: 16px;
                    transition: all 0.5s;
                    border-radius: 4px;
                    &:hover {
                        background: darken($c1, 20%);
                    }
                }
            }
        }
    }
    .map {
        iframe {
            display: block;
            width: 100%;
            height: 500px;
        }
    }
}