.delete-category-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .delete-category-dialog-box {
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    width: 100%;
    max-width: 500px;
    position: relative;
    text-align: center;
  }
  
  .delete-category-dialog-box h2 {
    margin-bottom: 1rem;
    font-size: 1.8rem;
    color: #333;
  }
  
  .delete-category-dialog-box p {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    color: #666;
  }
  
  .delete-category-dialog-box form {
    display: flex;
    flex-direction: column;
  }
  
  .delete-category-dialog-box button {
    background-color: #b60707;
    color: white;
    border: none;
    padding: 0.8rem;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .delete-category-dialog-box button:hover {
    background-color: #670505;
  }
  