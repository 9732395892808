.shopping-list {
    background-color: #fafafa;
    padding: 25px 0px;
    min-height: 100vh;

    .shopping-list-header {
        font-size: 22px;
        display: flex;
        align-items: center;
        margin: 0;
        margin-bottom: 15px;
        margin-right: 8%;
        color: $c1;

        img {
            margin-left: 5px;
            height: 35px;
        }
    }

    .list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-right: 8%;

        .list-data {
            border: none;
            margin: 10px;
            width: 150px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            .list-name {
                margin: 10px;
                color: #fff;
                width: 70px;
                height: 65px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                text-decoration: none;
                cursor: pointer;
            }

            .btn-add {
                border: none;
                background-color: $c1;
                color: #fff;
                border-radius: 10px;
                padding: 5px;
                margin: 5px 0px;
                cursor: pointer;
            }

            .delete {
                position: absolute;
                right: 0px;
                top: -3px;
                background-color: #fff;
                height: 25px;
                border-radius: 50%;
                cursor: pointer;

                &:hover {
                    background-color: #f2f2f2;
                    transform: scale(1.5);
                }
            }

        }

    }

    .empty-list {
        display: flex;
        flex-direction: column;
    }

    .add-item {
        input {
            width: 85%;
            height: 15%;
            margin-top: 23%;
            margin-right: 5%;
            margin-bottom: 3%;
            font-size: 13px;
        }

        .btn-add-item {
            width: 63%;
            color: $c1;
            margin-right: 15%;
            border: 1px solid $c1;
            border-radius: 15px;
            margin-top: 5%;
            height: 23%;
        }
    }

}
.shopping-list-product{
    margin-right: 15px;

    table{
        th, tr, td{
            margin: 2px;
            align-items: center;  
            font-size: 18px;
  
        }
        input{
            width: 60px;
            font-size: 18px;
        }
        .btn{
            padding: 5px 15px;
            font-size: 18px;
            margin: 5px;
        }
    }
}