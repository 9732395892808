.delivery-time {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    padding: 25px 15px 10px 15px;
    background-color: #fff;
    border-radius: 0px 0px 30px 30px;
    .day-title {
        font-size: 16px;
    }

    .list-hours {
        display: flex;

        ul {
            padding: 0;
            margin: 4px 0px;

            .ul-hour {
                font-size: 14px;
                padding: 10px 7px;
                border-radius: 25px;
                background-color: #f2f2f2;
                color: black;
                margin: 5px;

                &:hover {
                    opacity: 0.5;
                    border-radius: 25px;
                    background-color: $c1;
                    color: white;
                }
            }

            .checked-time {
                font-size: 14px;
                padding: 10px 7px;
                border-radius: 25px;
                background-color: $c1;
                color: white;
                margin: 4px 6px;
            }

            .can-not-check {
                font-size: 14px;
                padding: 10px 7px;
                border-radius: 25px;
                color: #b3b3b3;
            }

        }
    }
}

.delivery-hours-calc {
    display: flex;
    flex-direction: column;

    .input-class {
        display: flex;
        align-items: center;
        margin: 2px;

        input {
            width: 15%;
            box-sizing: border-box;
            height: 25px;
            border: 1px solid #ddd;
            margin: 0px 10px;
            font-size: 16px;
            color: #000;
        }
    }

    h2 {
        padding: 0px;
        margin: 3px;
        font-size: 18px;
    }

    .btn {
        color: #fff;
        padding: 5px 15px;
        border-radius: 5px;
        margin: 0px;
        margin-right: 5px;
        background-color: $c1;
        cursor: pointer;

        &:hover {
            border: 1px solid $c1;
            background-color: #fff;
            color: $c1 !important;
        }
    }
}

.delivery-day-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.delivery-day-type,
.delivery-time-day-of-week,
.delivery-time-special-day {

    .table-data {
        table {
            max-width: 1200px;
            align-items: center;
            width: 96%;
            margin: 10px auto;
            padding: 20px 8px;
            border: 1px solid black;

            th {
                text-align: center;
                background-color: $c1;
                margin: 5px 0px;
            }

            tr {
                text-align: center;
                padding: 5px 0px;
            }

            td {
                margin: 5px 0px;
            }
        }

    }

    input {
        width: 90%;
        height: 100%;
    }

    .btn {
        margin: 0px;
        background-color: rgb(187, 176, 176);
        padding: 2px 20px;
    }
}

.date {
    display: flex;
    flex-direction: row;
}

.date-day {
    width: 40%;
}

.delivery-time-day-of-week {
    width: 80%;
}