.category-edit {
    min-height: 100vh;
    padding-bottom: 40px;

    .items-container {
        background: #fff;
        padding: 0 24px 24px 24px;
        margin: 24px auto;

        .add-category {
            width: 50%;
            display: flex;
            align-items: center;

            label {
                padding-left: 5px;
            }

            input {
                width: 50%;
                padding: 5px;
                margin: 5px;
                font-size: 18px;
            }
        }

        .btn {
            background-color: $c1;
            padding: 8px 10px;
            border-radius: 10px;
            color: #fff;
            border: 1px solid $c1;

            &:hover {
                color: $c1;
                background-color: #fff;

            }
        }

        .search {
            display: flex;
            align-items: center;
            position: relative;

            input {
                padding: 5px;
                margin: 5px;
                font-size: 18px;
            }

            img {
                position: absolute;
                width: 20px;
                left: 12px;

                &.close {
                    cursor: pointer;
                }
            }
        }
    }

    .category-edit-table {
        table {
            width: 96%;

            tr:nth-child(even) {
                background-color: #f9f9f9;
            }

            tr {

                &:hover {
                    background: #f1f1f1;
                }
            }
        }

        .enter-img {
            img {
                width: 30px;
            }

        }

        .status-img {
            background-color: gray;

            img {
                width: 30px;
            }

        }

        .sort-item {
            ul {
                list-style-type: none;
                // padding: 0px;
                display: flex;
                justify-content: center;

                li {
                    // background: transparent;
                    // padding: 10px 0;
                    // width: 40px;
                    // cursor: pointer;
                    // border-radius: 2px;
                    // margin: 0 auto;

                    &:active {
                        transform: scale(0.9);
                    }

                    &:hover {
                        background: #909090;

                        img {
                            filter: invert(1) brightness(4);
                        }
                    }

                    img {
                        display: block;
                        width: 20px;
                        margin: 0 auto;
                    }
                }
            }
        }

        .category-img {
            position: relative;

            .masc {
                position: absolute;
                top: -80px;
                right: 80px;
                width: 400px;
                z-index: 99;
            }

            img.main-img {
                border: none;
                object-fit: cover;
                height: 100%;
                display: block;
            }

            .img-load {
                width: 80px;
                height: 80px;
                margin: 0 auto;
            }

        }

        .title {
            text-align: center;

            input {
                border-radius: 4px;
                padding: 4px;
                font-size: 16px;
            }

        }

    }
}