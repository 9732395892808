.show-sales {
    display: flex;
    flex-direction: column;

    .sale-item-list {
        display: flex;

        .sale-item {
            color: #fff;
            font-size: 20px;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 130px;
            flex-wrap: wrap;
            text-align: center;
        }
    }

    .btn {
        width: 135px;

        a {
            color: #fff;
            text-decoration: none;
            font-weight: bold;
        }

        &:hover {
            a {
                color: $c1;
            }
        }

    }
}

.add-new-sale {
    .sale-form {
        margin-right: 15px;

        label {
            margin-left: 5px;
        }

        input {
            height: 20px;
            font-size: 16px;
            margin: 3px;
        }

        .btn {
            height: 40px;
            font-size: 20px;
            margin: 10px;
        }
    }

    .go-back-to-sale-list {
        cursor: pointer;

        img {
            transform: rotate(180deg);
            margin: 10px;
        }
    }
}

.show-sales-table,
.show-product-table {
    margin-right: 10px;

    .add-station,
    .add-product {
        margin: 10px 0px;
        font-size: 18px;
        border-radius: 10px;
        padding: 5px 10px;

        &:hover {
            background-color: $c1;
            color: #fff;
            border: $c1;
        }
    }

    table,
    th,
    td {
        border: 1px solid black;
        border-collapse: collapse;
        text-align: center;
        padding: 5px;
    }
}

.add-new-station {
    form {
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
            margin: 5px;
            font-size: 18px;

            input {
                font-size: 18px;
                width: 70px;
                margin-right: 3px;
            }
        }
    }
}

.add-new-product {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px;

    .input-class {
        display: flex;
        align-items: center;

        h2 {
            font-size: 18px;
            font-weight: 500;
        }

        input {
            height: 20px;
            margin: 0px 5px;
        }

    }
}
.all-sale-list{
    position: relative; 
    display: inline-block; 
    width: 140px; 
    height: 130px; 
    margin: 10px;
}

.saleRole{
	padding: 10px;
	color: $c1;
}

.deleteSale {
    position: absolute;
    right: 15px;
    background-color: #fff;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;

    &:hover{
        height: 40px;
    }
}